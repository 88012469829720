<template>
  <div>
    <div class="index">
      <!-- <div class="banner">
        <el-carousel indicator-position="outside">
          <el-carousel-item v-for="(item,index) in swiperImage[0].image_fir " :key="index">
            <img :src="item" alt="" style="height: auto;">
          </el-carousel-item>
        </el-carousel>
      </div> -->
      <Banner/>
      <div class="company_view">
        <div class="l">
          <div class="company-name">{{companyProfile[0].title}}</div>
          <div class="span"></div>
          <div class="company-desc">
            {{$deTag(companyProfile[0].content)}}
          </div>
          <a class="more" href="./about"> <u>查看更多</u> <span class="iconfont icon-next"></span></a>
          <!-- <div class="company-numbers">
            <div class="numbers-item">
              <div class="item-num" data-target="10000" data-duration="3000"><span
                  id="company-area">{{companyProfile[0].area}}</span></div>
              <div class="item-name">企业面积</div>
            </div>
            <div class="numbers-item">
              <div class="item-num" data-target="30000" data-duration="3000"><span
                  id="company-make">{{companyProfile[0].product}}</span></div>
              <div class="item-name">生产能力</div>
            </div>
            <div class="numbers-item">
              <div class="item-num" data-target="1.2" data-duration="3000"><span
                  id="company-invest">{{companyProfile[0].device}}亿</span></div>
              <div class="item-name">设备投资</div>
            </div>
          </div> -->
        </div>
        <div class="r video-item">
          <iframe class="item-video"   id="dfvideo" ref="videoPlayer"
          :src="companyProfile[0].video_url" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"></iframe>
          <div class="playbtn"></div>
          <div class="mask"></div>
        </div>
      </div>
      <div class="index-card">
        <div class="card-title container">热门产品
          <router-link class="more" to="/products">查看更多<span class="iconfont icon-next"></span></router-link>
        </div>
        <div class="tabs">
          <ul class="tab-titles">
            <li class="tab-title" :class="{active:activeTab===index}" v-for="(group,index) in groupedData"
              @mouseover="activeTab = index">
              {{group.categoryName}}
            </li>
          </ul>
          <div class="tab-content container">
            <div class="child " 
            :class="{hidden:activeTab!==index}" v-for="(group,index) in groupedData">
              <ul class="list_view"  
              @mouseenter="onMouseEnter"
              @mouseleave="onMouseLeave"
              :style="{ overflow: isHover ? 'auto' : 'hidden' }">
                <li v-for="(item,index) in group.products" :key="item.index">
                  <router-link :to="{name:'productDetail',params:{id:item.productId}}">
                    <div class="item-pic">
                      <div class="item-pic-view">
                        <img v-lazy="item.imageUrl[0]" alt="pic">
                      </div>
                    </div>
                    <div class="item-text">{{item.productName}}</div>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="index-card certificate">
        <div class="card-title container">{{homeCer[0].title}}</div>
        <div class="card-desc" v-html="homeCer[0].content"></div>
        <div class="certificate-banner">
          <div class="cert_banner swiper-container-initialized swiper-container-horizontal">
            <swiper :options="certificateSwiper">
              <swiper-slide v-for="(item,index) in homeCer[0].image_cer" :key="index">
                <a href="">
                  <img :src="item" />
                </a>
              </swiper-slide>
            </swiper>
            <div class="swiper-pagination"></div>
          </div>
        </div>
        <div class="cert_menu container">
          <div class="menu-item" v-for="(item,index) in homeCer[0].image_aut">
            <img :src="item" />
          </div>
        </div>
        <div class="card-desc">通过ISO9001-2015认证、QS认证、符合FDA标准</div>
      </div>
      <div class="index-card">
        <div class="card-title container">{{partnerList[0].title}}</div>
        <div class="partners container">
          <div class="partners_banner swiper-container-initialized swiper-container-horizontal">
            <swiper :options="partnerSwiper">
              <swiper-slide v-for="(item,index) in partnerList[0].image_par" :key="index">
                <img :src="item" alt="">
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
      <div class="container research">
        <div class="research-top">
          <div class="l">
            <div class="l-title">
              <span></span>{{HomerdList[0].title}}
            </div>
            <div class="l-desc">{{$deTag(HomerdList[0].content)}}</div>
          </div>
          <div class="r">
            <div class="r-item">
              <div class="item-title">管理</div>
              <div class="item-subtitle">Manage</div>
            </div>
            <div class="r-item">
              <div class="item-title">生产</div>
              <div class="item-subtitle">Produce</div>
            </div>
            <div class="r-item">
              <div class="item-title">仓储</div>
              <div class="item-subtitle">Storehouse</div>
            </div>
          </div>
        </div>
        <div class="research-bot">
          <div class="pic-list" >
            <div class="pic-item" v-for="(item,index) in HomerdList[0].image" :key="index">
              <img :src="item" alt="">
            </div>
          </div>
          <div class="r">
            <div class="r-title">镀膜专用检测仪器</div>
            <div class="r-subtitle">专用仪器、专业技术</div>
            <div class="text-list">
              <div class="text-item">
                <div class="text-icon">
                  <img src="../assets/imgs/shape01.png" alt="">
                </div>
                <div class="text-text">经验丰富的基础技术人员</div>
              </div>
              <div class="text-item">
                <div class="text-icon">
                  <img src="../assets/imgs/shape01.png" alt="">
                </div>
                <div class="text-text">专业化服务帮助客户创造更高的价值</div>
              </div>
              <div class="text-item">
                <div class="text-icon">
                  <img src="../assets/imgs/shape01.png" alt="">
                </div>
                <div class="text-text">贴心售后服务团队</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container quality">
        <div class="quality-title">
          <span></span> {{companyProfile[4].title}}
        </div>
        <div class="quality-list">
          <a class="quality-item" v-for="(item,index) in controlList" :key='index'>
            <div class="item-pic">
              <div class="item-pic-view">
                <img :src="item.image_url" alt="">
              </div>
            </div>
            <div class="item-text">{{item.title}}</div>
          </a>
        </div>
      </div>
      <div class="advantage">
        <div class="advantage_bg">
          <img src="../assets/imgs/bg04.png" alt="">
        </div>
        <div class="advantage-list container">
          <div class="list-item">
            <div class="item-text">
              广东彩龙新材料股份有限公司创立于2006年 公司创始人林先生在FSPG工作了20多年，有丰富的工业经验 现任中国塑料加工工业协会金属化薄膜行业常务副主席
            </div>
            <div class="item-icon">
              <img src="../assets/imgs/ico01.png" alt="">
            </div>
          </div>
          <div class="list-item">
            <div class="item-icon">
              <img src="../assets/imgs/ico04.png" alt="">
            </div>
            <div class="item-text">
              荣获“国家高新区年度领军企业”“国家高新区年度瞪羚企业并获得多项国家 专利，参制定《食品包装用氧化物阻隔透明塑料复合膜、袋质量通则》国家标准 GB/T40266-2021，也是行业标准”包装用透明镀氧化铝薄膜”牵头制定单位
            </div>
          </div>
          <div class="list-item">
            <div class="item-text">
              产品广泛用于彩印包装，不干胶标签，装饰材料，食品包装、医药包装、 皮革、纺织、电子、光伏、光电、保温材料等行业
            </div>
            <div class="item-icon">
              <img src="../assets/imgs/ico02.png" alt="">
            </div>
          </div>
          <div class="list-item">
            <div class="item-icon">
              <img src="../assets/imgs/ico05.png" alt="">
            </div>
            <div class="item-text">
              引进了多台日本、德国真空镀铝机，堆塌镀铝机及配套多台高水平的分切机 搭配了万级无尘的洁净车间，且通过了ISO9001、ISO14001、QS认证、 符合FDA及MSDS标准要求
            </div>
          </div>
          <div class="list-item">
            <div class="item-text">
              凭借雄厚的技术积累，秉持技术创新和管理创新理念，佛山彩龙正致力于推动 中国高阻隔包装薄膜材料产业的快速发展，达到全面代替同类进口产品的新征程
            </div>
            <div class="item-icon">
              <img src="../assets/imgs/ico03.png" alt="">
            </div>
          </div>
          <div class="list-item">
            <div class="item-icon">
              <img src="../assets/imgs/ico06.png" alt="">
            </div>
            <div class="item-text">
              MOCON PERMSTRAN-W的全球顶级WVTR/OTR测试仪
            </div>
          </div>
        </div>
      </div>
      <div class="index_news">
        <div class="container">
          <div class="l">
            <img src="../assets/imgs/bg06.png">
          </div>
          <div class="r">
            <div class="r-top">
              <div class="r-title">
                <span></span> 企业资讯
              </div>
              <router-link class="more" to="/news">查看更多 <span class="iconfont icon-next"></span></router-link>
            </div>
            <div class="news-list">
              <router-link :to="{name:routeName(item.category_id),params:{id:item.id}}" v-for="(item,index) in newsList.slice(0,4)"
                :key="index">
                <div id="news6" class="list-item" style="margin-bottom: 25px;">
                  <div class="item-time">
                    <div class="item-time-day">
                      {{item.create_time.day}} <span>/</span>
                    </div>
                    <div class="item-time-more">
                      <span>{{item.create_time.year}}</span>
                      <span>{{item.create_time.month}}</span>
                    </div>
                  </div>
                  <div class="item-main">
                    <div class="item-title">{{item.title}}</div>
                    <div class="item-desc" v-html="item.description"></div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="index-card notes">
        <div class="card-title container">在线留言</div>
        <div class="notes-form container">
          <div class="two-input">
            <div class="form-input">
              <div class="form-input-label">
                <span class="iconfont icon-name"></span>
                <span>姓名：</span>
              </div>
              <div class="form-input-value">
                <input type="text" v-model="btnForm.name">
              </div>
            </div>
            <div class="form-input">
              <div class="form-input-label">
                <span class="iconfont icon-phone"></span>
                <span>电话 (必填)：</span>
              </div>
              <div class="form-input-value">
                <input type="text" v-model="btnForm.phone">
              </div>
            </div>
          </div>
          <div class="form-input">
            <div class="form-input-label">
              <span class="iconfont icon-mail"></span>
              <span>邮箱：</span>
            </div>
            <div class="form-input-value">
              <input type="text" v-model="btnForm.email">
            </div>
          </div>
          <div class="form-textarea">
            <div class="form-input-label">
              <span class="iconfont icon-note"></span>
              <span>内容：</span>
            </div>
            <div class="form-textarea-value">
              <textarea name="" id="" cols="30" rows="4" v-model="btnForm.content"></textarea>
            </div>
          </div>
          <a href="javascript:;" class="form-submit" @click="btnSubmit()">
            免费索样
          </a>
        </div>
      </div>
    </div>
    <div id="backTop">
      <span class="iconfont icon-fanhuijiantou-copy"></span>
    </div>
  </div>
</template>
<script>
  import Banner from '@/components/banner/index.vue'
  export default {
    components:{
      Banner
    },
    data() {
      return {
        isHover: false,
        selectedIndex:0,
        keyword: '',
        showHot: [],
        category: [],
        activeTab: 0,
        profileList: [],
        advantage: [],
        controlList: [],
        productCategory: [],
        homeCer:[],
        partnerList:[],
        HomerdList:[],
        rdCenterList:[],
        swiperImage: [],
        companyProfile: [],
        productHotList: [],
        groupedData: [],
        newsList: [],
        btnForm: {},
        certificateSwiper: {
          loop: true,
          autoHeight: true,
          speed: 1000,
          autoplay: true,
        },
        partnerSwiper: {
          loop: true,
          autoHeight: true,
          autoPlay: true,
          speed: 1000,
          delay: 3000,
          slidesPerView: 'auto',
          autoplay: {
            disableOnInteraction: false
          },
        }
      }
    },
    mounted() {
      this.getProfile();
      this.getProduct();
      this.getControl();
      this.getHomeCer();
      this.getNews();
      this.getPartner();
      this.getHomerd();
    },
    methods: {
      onMouseEnter() {
      this.isHover = true;
    },
    onMouseLeave() {
      this.isHover = false;
    },
      //获取首页资质证书
      async getHomeCer() {
        const res = await this.$request.get("homecertification/getPaginate");
        if (res.data.code === 0) {
          this.homeCer = res.data.data;
          this.homeCer[0].image_cer = this.homeCer[0].image_cer.split(',')
          this.homeCer[0].image_aut = this.homeCer[0].image_aut.split(',')
        }
      },
      //获取首页合作伙伴
      async getPartner() {
        const res = await this.$request.get("partner/getPaginate");
        if (res.data.code === 0) {
          this.partnerList = res.data.data.data;
          this.partnerList[0].image_par = this.partnerList[0].image_par.split(',')
        }
      },
      //获取研发中心
      async getHomerd() {
        const res = await this.$request.get("Homerd/getPaginate");
        if (res.data.code === 0) {
          this.HomerdList = res.data.data.data;
          this.HomerdList[0].image = this.HomerdList[0].image.split(',')
        }
      },
      //获取公司简介和各种一级标题
      async getProfile() {
        const res = await this.$request.get('Profile/getPaginate');
        if (res.data.code === 0) {
          this.companyProfile = res.data.data.data     
        }
      },
      //获取热门产品
      async getProduct() {
        const res = await this.$request.get('product/getHot');
        if (res.data.code === 0) {
          this.productHotList = res.data.data;
          this.productHotList.reverse()
          this.productHotList.forEach((item) => {
            item.image_url = item.image_url.split(',')
          })
        }
        const grouped = this.productHotList.reduce((acc, item) => {
          const categoryKey = item.category_name;
          if (!acc[categoryKey]) {
            acc[categoryKey] = {
              categoryName: item.category_name,
              products: [],
            };
          }
          acc[categoryKey].products.push({
            productName: item.name,
            imageUrl: item.image_url,
            id: item.category_id,
            productId: item.id
          });
          return acc;
        }, {});
        this.groupedData = Object.values(grouped);
      },
      //获取企业资讯消息
      async getNews() {
        const res = await this.$request.post("News/getPaginate", {
          fil: 2,
        });
        if (res.data.code === 0) {
          this.pageData = res.data.data;
          this.newsList = res.data.data;
          //是否显示
          this.newsList = this.newsList.filter(item => item.is_hot === 1 && item.is_show === 1);
        }
        //整理时间
        this.newsList.newsTime = this.newsList.map((item, index) => {
          let obj = {};
          let time1 = item.created_time.split(' ');
          let time = time1[0].split('-');
          obj.year = time[0];
          obj.month = time[1];
          obj.day = time[2]
          return item.create_time = obj
        })
      },
     //判断是哪个分类的资讯
      routeName(type){
         switch (type) {
          case 1:
            return 'newsDetail'
          case 2:
            return 'reportDetail'
          case 3:
            return 'exhibitionDetail'
          default:
            return 'newsDetail';
         }
      },
      //获取质量控制热门
     async getControl() {
        const res = await this.$request.post('control/getpaginate',{
          fil:1
        });
        if (res.data.code === 0) {
          this.controlList = res.data.data.data.filter(item => item.is_hot === 1)
        }
      },
      //提交检索
      async btnSubmit() {
        this.loading = true;
        let res = await this.$request.post('online/add', this.btnForm)
        this.loading = false
        if (res.data.code === 0) {
          this.$message.success("提交成功")
        } else {
          this.$message.error("提交失败")
        }
      }

    },
  }
</script>
<style lang="scss">
  .el-carousel__container {
    height: 850px !important;
  }
</style>