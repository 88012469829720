/**
 * 服务器请求文件
 * @return {String}
 */
export const webServiceUrl = '/ht/'

/**
 * 验证表单
 * @param {object} refs
 */
export const validate = (refs) => {
  return new Promise((resolve, reject) => {
    refs.validate((valid, a) => {
      if (valid) {
        resolve()
      } else {
        refs.$message({
          dangerouslyUseHTMLString: true,
          message: '请先完善必填内容',
          type: 'error'
        })
      }
    });
  })
}

/**
 * 确认提示
 * @param {string} title 
 */
export const confirm = (title) => {
  return new Promise((resolve, reject) => {
    Vue.prototype.$confirm(title, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning"
    }).then(() => {
      resolve()
    }).catch(() => {
    })
  })
}